.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px;

    p {
        font-size: x-large;
        text-align: center;
        color: #ced4da;
        text-transform: uppercase;
    }
}

.reset {
    cursor: pointer;
    color: #BA1274;

    &:hover {
        color: #ED36B9;
        text-decoration: underline;
    }

}
