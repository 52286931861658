.customFormGroup {
    margin-top: 32px;

    input {
        border-color: #00389C;
        border-width: 2px;
        border-radius: 8px;
    }
}

.dropAreaWrap {
    margin-top: 32px;
    border: solid;
    border-color: #00389C;
    border-width: 2px;
    border-radius: 8px;
    background-color: #d9dbf0;
    display: flex;
    flex-direction: column;
    padding: 36px 12px;
}