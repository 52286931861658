.wrap {
    position: absolute;
    z-index: 5;
    top: 34px;
}

.inputs {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 8px 10px;

    border: 1px solid #eee;
    border-radius: 3px;

    input {
        margin: 4px 0
    }
}