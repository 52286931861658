.modal {
    max-width: 600px !important;

    .rowReactSortable {
        display: flex;
        width: inherit;

        & > * {
            width: 100%;
        }

        .button {
            width: 200px;
            margin-right: auto !important;
            justify-content: center;
            user-select: none;
        }
    }
}
