.customPagination {
    margin: 0;

    & > li {
        &:first-child {
            & > a, span {
                border-top-left-radius: 10px !important;
                border-bottom-left-radius: 10px !important;
            }
        }
        &:last-child {
            & > a, span {
                border-top-right-radius: 10px !important;
                border-bottom-right-radius: 10px !important;
            }
        }
    }
}
