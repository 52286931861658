.wrap {
    display: flex;
    gap: 60px;
    align-items: center;
    background-color: rgb(254,244,208);
    border: 2px solid;
    border-color: rgb(250,179,51);
    border-radius: 20px;
    padding: 16px 40px;
    margin: 12px 20px 12px 0;

    .icon {
        height: 56px;
    }

    p {
        margin: 0;
        font-family: "Montserrat", sans-serif;

        a {
            color: rgb(250,179,51);
        }
    }
}
