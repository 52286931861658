.modalEditCar {
    &__imagesWrap {
        display: flex;
        margin-left: 25px;

        img {
            margin-left: 10px;

            width: 50px;
            height: 50px;
            object-fit: contain;
        }
    }

    &__modalSubTitle {
        font-size: large;
    }

    &__formLabel {
        font-size: x-small;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0;
    }

    &__formLabelFlex {
        align-self: flex-start;
        font-size: x-small;
        font-weight: normal;
        margin-bottom: 0;
    }

    &__alignLeftColumn {
        align-self: flex-start;
    }

    &__textarea {
        font-size: small;
    }

    &__priceChart {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
        flex-direction: column;


        img {
            width: 350px;
            //height: 400px;
            object-fit: contain;
        }
    }
}

.counter {
    color: rgb(192, 191, 199)
}

.counterErr {
    color: white;
    background-color: #EB4F4F;
    padding: 3px;
    border-radius: 4px;

}

.icon {
    margin-left: 10px;
    cursor: pointer;
    padding-bottom: 5px;
}

.titleWrap {
    display: flex;
    align-items: center;
}

.wrap {
    display: flex;
    justify-content: center;
}

.contentWrap {
    flex: 1;
    margin: 0 auto;
    max-width: 1180px;
    padding-bottom: 24px;
}

.cardContentWrap {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-bottom: 12px;
    flex-wrap: wrap;
}

.cardColumnWrap {
    width: 530px;
    display: flex;
}

.leftFooter {
    display: flex;
}

.modalImgGroup {
    padding-top: 8px;
}
