$mobile: 576px;
$tablet: 768px;
$laptop: 992px;
$desktop: 1200px;


@mixin bp-mobile-down {
    @media (max-width: $mobile) {
        @content
    };
}

@mixin bp-mobile-up {
    @media (min-width: $mobile) {
        @content
    };
}

@mixin bp-tablet-down {
    @media (max-width: $tablet) {
        @content
    };
}

@mixin bp-tablet-up {
    @media (min-width: $tablet) {
        @content
    };
}

@mixin bp-laptop-down {
    @media (max-width: $laptop) {
        @content
    };
}

@mixin bp-laptop-up {
    @media (min-width: $laptop) {
        @content
    };
}

@mixin bp-desktop-down {
    @media (max-width: $desktop) {
        @content
    };
}

@mixin bp-desktop-up {
    @media (min-width: $desktop) {
        @content
    };
}