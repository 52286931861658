.wrap {
    position: relative;
}
.clipboardAlert {
    position: absolute;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #FFF;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    top: 8px;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.icon {
    margin-left: 10px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-right: 5px;
}

.inputWrap {
    display: flex;
    flex: 1;
}

.isRequired {
    background-color: #ED006D;
    border: 3px solid rgba(237, 0, 109, 0.5);
    margin-left: auto;
    margin-right: 5px;
    border-radius: 50%;
    align-self: center;
}

.checkboxGroup {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 3px;
    column-gap: 20px;
}

.plusIcon {
    cursor: pointer;
    margin-right: 6px;
}

.clearIcon {
    cursor: pointer;
    margin-right: 10px;
}

.mainPartNumber {
    margin-top: 12px;
    margin-bottom: 12px;
    flex-grow: 1;
}

.customLink {
    display: flex;
    justify-content: center;
    margin-top: 4px;
}

.applicabilityClearIcon {
    cursor: pointer;
    margin-left: 8px;
}

.accordion {
    margin-left: auto;
    .accordionButton {
        &:not(.collapsed) {
            background-color: #FFF;
            box-shadow: none;
        }
    }
}

.textOverlowEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}
