@use './global';

.wrap {
    display: flex;
    flex: 1;
    // flex-basis: 100%;
    height: 164px;
    align-self: stretch;
    background: #EBEBF0;
    color: #FFFFFF;
    justify-content: center;
    align-items: stretch;
    padding: 40px 0;
    //margin-top: 16px;


    @include global.bp-laptop-down {
        height: auto;
        white-space: nowrap;
        padding: 20px 0;

    }
}

.content {
    display: flex;
    flex: 1;
    max-width: 1160px;
    align-items: stretch;
    justify-content: space-between;

    @include global.bp-desktop-down {
        margin: 0 30px;
    }

    @include global.bp-tablet-down {
        flex-direction: column;
        padding-bottom: 20px;
    }

}

.store {
    height: 37px;
}

.storeWrap {
    &:not(:first-of-type) {
        margin-left: 21px;
    }
}

.paySystem {
    &:not(:first-of-type) {
        margin-left: 13px;
    }
}

.callToAction {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.leftSubContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;

    @include global.bp-mobile-down {
        flex-direction: column;
    }
}

.rightSubContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;

    @include global.bp-tablet-down {
        margin-top: 16px;
        margin-right: 16px;
    }
}

.textWrap {
    margin-right: 40px;

    @include global.bp-mobile-down {
        margin-right: 0;
    }
}
