.wrap{
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
}

.filtered {
    color: #BA1274;
    font-weight: 800;
}

.icon {
    margin-left: 10px;
    cursor: pointer;
}

.selectSearchWrap {
    width: 100%;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    top: -31px;
    left: -6px;
    z-index: 5;
}

.tableHeadWrap {
    cursor: pointer;
}
