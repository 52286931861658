.icon {
    margin-left: 8px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-right: 5px;
}

.copyText {
    cursor: pointer;
}
