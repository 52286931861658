.wrap {
    top: 236px;
    margin:0 65px 0 29px;
    position: sticky;

}
.navLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__link {
        //padding: 10px;
        text-align: center;
        text-decoration: none;
        text-transform: lowercase;
        color: #FFF;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        align-items: center;
        justify-items: center;
        margin-bottom: 20px;

        svg {
            fill: #FFF;
            width: 36px;
            height: 36px;
            rect {
                fill: #00389C;
            }

            &:hover {
                rect {
                    //fill: #ED36B9
                }
            }
        }

        &__active {
            svg {
                fill: #FFF;
                background-color: #ED006D !important;
                border-radius: 8px;
                rect {
                    fill: #ED006D;
                }
            }
        }
    }
}
