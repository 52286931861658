@use './global';

.authWrap {
    display: flex;
    flex: 1;
    height: 100vh;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.authBox {
    display: flex;
    flex: 5;
    flex-basis: 100%;
    justify-content: center;
    padding-bottom: 16px;
    align-self: center;
    align-items: center;

    @include global.bp-mobile-down {
        flex-direction: column-reverse;
    }

    &__description {
        max-width: 320px;
    }

    &__subtitle {
        font-weight: bold;
    }

    &__border {
        border-left: 2px solid #EBEBF0;
        margin: 0 150px;
        @include global.bp-laptop-down {
            margin: 0 30px;

        }

        @include global.bp-mobile-down {
            margin: 10px 0;

        }
    }

    &__qrContainer {
        display: flex;
        flex-direction: column;
        margin-top: 44px;
    }

    &__qr {
        width: 110px;
        border: 2px solid #ED006D;
        padding: 5px;
        border-radius: 8px;
    }

    &__link {
        color: #00266a;
        margin-bottom: 16px;

        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.authHocWrap {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 85vh;

    &__content {
        display: flex;
        flex-direction: column;

        p {
            //font-family: 'Montserrat', sans-serif;
            color: #00266A;
            font-size: 18px;
        }
    }
}
