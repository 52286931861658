.wrap {
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    width: 1180px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.sortControls {
    display: flex;
    gap: 8px;
}

.titleWrapRight { 
    margin-left: auto;
}

.row {
    position: relative;
    cursor: pointer;
    transform: none;
}
