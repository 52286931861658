.wrap {
    font-family: 'Montserrat',sans-serif;
    position: absolute;
    z-index: 2;
    top: 45px;

    /* SCROLL BAR START */

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        height: 80px;
        background-color: #626669;
        opacity: 0.6;
        border-radius: 5px;
        padding: 10px;
    }

    /* SCROLL BAR END */
}
