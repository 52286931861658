.wrap {
    display:flex;
}

.tableHead {
    cursor:pointer;
    transition: all .5s ease;
}

.clearIcon {
    display: flex;
    padding-left: 5px;
    z-index: 5;
    cursor:pointer;
}

.tableHead {
    position: relative;
}
