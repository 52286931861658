.wrap {
    font-family: 'Montserrat', sans-serif;
    color: #00389C;
    font-size: 18px;
    box-sizing: border-box;
    border: 1px solid transparent;
}

.wrapWithBorder {
    border: 1px solid #EBEBF0;
}

.userInfoWrap {
    padding: 15px 26px;
    display: flex;
    align-items: center;
}

.avatar {
    margin-left: 20px;
}

.menuItemsWrap {
    position: absolute;
    z-index:1;
    width: 233px;
    padding: 0 26px 10px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 12px 20px rgba(0, 23, 64, 0.08);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #FFF;
}

.menuItem {
    margin-top: 10px;
}



