.wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    &__textContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__logo {
        width: 36px;
        margin-right: 13px;
    }

    &__text {
        width: 120px;
    }

    p {
        font-family: 'Montserrat', sans-serif;
        margin: 8px 0 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
    }
}
