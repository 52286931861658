.soldOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    cursor: not-allowed;

    div {
        opacity: 0.7;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 98;
    }

    p {
        font-size: 25px;
        font-weight: 800;
        z-index: 99;
        margin: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.children {
    z-index: 100;
}
