.wrap {
    font-family: 'Montserrat',sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}
.input {
    display: flex;
    flex: 1;
    padding: 6px 20px;
    border-radius: 10px;
    height: 36px;
    background-color: #F6F7FA;
    border: none;

    &:focus {
        outline: none;
    }
}

.suffixPadding {
    padding-right: 35px
}

.suffixElement {
    display: flex;
    align-items: center;
    position: absolute;
    //top: 10px;
    height: 100%;
    right: 3px;
}

.disabled {
    background-color: #C0BFC7;
    color: #F6F7FA
}

.error {
    border-color: #ED006D;
    border-width: 1px;
    border-style: solid;
}
