.successBtn {
    height: 18px;
    filter: invert(1);
}

.icon {
    height: 40px;
    cursor: pointer;
    &:hover {
        opacity: 0.6;
    }
}

.cloneIcon {
    height: 18px;
    cursor: pointer;
    filter: invert(1);
}
