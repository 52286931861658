.wrap {
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    min-width: 1180px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.alignRightCell {
    text-align: right;
}

.row {
    cursor: pointer;
    position: relative;
    transform: scale(1, 1);

}

.titleWrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.wrapRight {
    margin-left: auto;
}
