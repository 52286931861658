.modalSellPart {
    padding: 24px 32px;

    .header {
        padding: 32px;
    }
}

.priceField {
    margin-bottom: 1em;
}

.buttonGroup {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 12px;
    position: relative;

    .icon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        height: 60px;
        cursor: pointer;
        &:hover {
            opacity: 0.6;
        }
    }
}