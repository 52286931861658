.wrap {
    position: relative
}

.sortIcon {
    margin-left: 2px;
    cursor: pointer;
}

.clearIcon {
    margin-left: 2px;
    cursor: pointer;
}

.sortIndex {
    position: absolute;
    right: -9px;
    top: -15px;
    padding: 1px 5px;
    border-radius: 3px;
    color: white;
    background-color: #BA1274;
    font-size: 9px;
}