.wrap {
    position: relative;
}
.clipboardAlert {
    position: absolute;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #FFF;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    top: 8px;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
