.modalDataDocument {
    padding: 24px 32px;

    .header {
        padding: 32px;
    }
}

.priceField {
    margin-bottom: 1em;
}

.buttonGroup {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 12px;
}

.row {
    display: flex;
    gap: 60px;
}
