.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentWrap {
    width: 1180px;
}

.cardsBackground {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 136px;
}

.cardsWrap {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
}

.cardContentWrap {
    display: flex;
    flex-wrap: wrap;
}

.cardLineWrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 10px;
}

.subtitleWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}

.customTooltip {
    outline: none;
    padding: 8px 16px;
    border: 1px solid black;
    border-radius: 16px;
    background-color: rgba(35, 32, 32, 0.8);
    color: rgb(239, 239, 239);
    .label {
        padding: 0;
        margin: 0;
        text-align: center;
        font-weight: bold;
    }
}

.chartControls {
    padding: 0 12px;
    margin: 8px 12px;
    display: flex;
    gap: 8px;
}

.monthName {
    text-transform: capitalize;
}
