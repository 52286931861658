@use "./global";

.wrap {
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 14%) 0 2px 2px 0, rgb(0 0 0 / 20%) 0 3px 1px -2px, rgb(0 0 0 / 12%) 0 1px 5px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: Roboto, arial, sans-serif;
    height: 288px;
    min-height: 288px;
    max-width: 360px;
    width: 360px;
    position: relative;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 24px 24px 24px 34px;
    margin-bottom: 140px;

    @include global.bp-mobile-down {
        margin: 20px 0;
    }

}

.title {
    padding-bottom: 16px;
    color: rgba(0, 0, 0, 0.87);
    direction: ltr;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    text-align: left;
}

.inputWrap {
    padding: 5px 0;
}

.submitButton {
    margin-top: 18px;
    margin-right: 1px;
    margin-left: 4px;
    align-self: flex-end;
    text-transform: uppercase;
}

.chargeWarning {
    color: #757575;
    direction: ltr;
    font-size: 12px;
    line-height: 16px;
    margin-top: 22px;
    text-align: left;
}

.codeSentText {
    margin: 0 0 16px 0;
    font-size: 14px;

    span {
        color: #BA1274
    }

    p {
        margin: 0;
    }
}

.codeSentButtonWrap {
    display: flex;
    justify-content: flex-end;
}

.resendButtonWrap {
    display: flex;
    justify-content: center;
}

.errorMessage {
    position: absolute;
    color: #EB4F4F;
    font-size: 10px;
    margin: 0;
}