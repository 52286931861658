.row {
    position: relative;
    &:first-of-type {
        border-top: none
    }

    & td {
        display: flex;
    }
    min-height: 60px;
    display: flex;
    // align-items: center;
    justify-content:stretch;
    border-top: 2px solid #EBEBF0
}

.debug{
    border: 1px solid green
}

.lastRowBottomBorder {
    border-bottom: 2px solid #EBEBF0
}

.removeBorderTop {
    border-top: none;
    margin-top: 1px;
}
