.wrap {
    padding-bottom: 94px;
    width: 1180px;
    margin: 0 auto;
}

.contentWrap {
    display: flex;
    margin-top: 12px;
}

.rightColumnWrap {
    padding: 20px;
    margin-left: 80px;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    img {
        width: 150px;
        object-fit:contain;
    }
}
