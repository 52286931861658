.wrap {
    font-family: 'Montserrat',sans-serif;
    width: 100%;
}

.label {
    width: 100%;
}

.dropAreaWrap {
    display: flex;
    flex: 1;
    padding: 20px 0;
    margin: 14px 0;
    background-color: #F6F7FA;
    border-radius: 10px;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
}

.disabled {
    background-color: #C0BFC7;
}

.iconWrap {
    margin-left: 20px;
    width: 36px;
    height: 36px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.thumbsWrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}
