.wrap {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    img {
        max-width: 50px;
        max-height: 40px;
        object-fit:contain;
    }
}