
.cardWap {
    font-family: 'Montserrat',sans-serif;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0 12px 20px rgba(0, 23, 64, 0.08);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.innerTitleWrap {
    display: flex;
}

.footer {
    margin: 0px -20px -20px;
    background-color:  #F6F7FA;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    align-self: stretch;
    height: 78px;
    display: flex;
    justify-content: flex-end;
}
.footerMarginTop {
   margin-top: 20px;
}

.justifyContentBetween {
    justify-content: space-between !important; 
}
