@use "./global";

.wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: right;
}

.headerLink {
    font-family: 'Montserrat', sans-serif;
    color: #00389C;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 40px;
    cursor: pointer;
    text-decoration: none;
    text-align: left;

    &:hover {
        text-decoration: underline;
        color: #00266A;
    }

    @include global.bp-laptop-down {
        font-size: 16px;
        margin-right: 10px;

    }

    @include global.bp-tablet-down {
        font-size: 14px;
        margin: 8px;
        white-space: nowrap;
    }
}

.upperText {
    font-size: 18px;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
    color: #00266A;
    margin-left: 24px;

    @include global.bp-laptop-down {
        font-size: 16px;
    }

    @include global.bp-tablet-down {
        font-size: 14px;
    }
}

.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
