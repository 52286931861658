.modalEditPart {
    &__imagesWrap {
        display: flex;
        margin-left: 25px;

        img {
            margin-left: 10px;

            width: 50px;
            height: 50px;
            object-fit: contain;
        }
    }

    &__modalSubTitle {
        font-size: small;
    }

    &__formLabel {
        font-size: 18px;
        font-weight: 600;
        color: rgb(27, 29, 44);
        margin: 0px;
        background-color: transparent;
        padding: 0px;
    }

    &__formLabelFlex {
        align-self: flex-start;
        font-size: x-small;
        font-weight: normal;
        margin-bottom: 0;
    }

    &__alignLeftColumn {
        align-self: flex-start;
    }

    &__textarea {
        font-size: small;
    }

    &__priceChart {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
        flex-direction: column;
        margin-bottom: 16px;

        img {
            width: 350px;
            //height: 400px;
            object-fit: contain;
        }
    }
}

.shareIcons {
    margin-left: 30px;
    ul {
        li {
            margin-bottom: 10px !important;
        }
    }
}

.counter {
    color: rgb(192, 191, 199)
}

.counterErr {
    color: white;
    background-color: #EB4F4F;
    padding: 3px;
    border-radius: 4px;

}

.icon {
    margin-left: 10px;
    cursor: pointer;
    padding-bottom: 5px;
    margin-right: 5px;
}

.vendorCode {
    margin-left: 16px;
    font-size: medium;
    font-weight: bold;
    color: darkgray;
}

.titleWrap {
    display: flex;
    align-items: center;
}

.wrap {
    display: flex;
    justify-content: center;
}

.contentWrap {
    flex: 1;
    margin: 0 auto;
    max-width: 1180px;
    padding-bottom: 24px;
}

.cardContentWrap {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-bottom: 12px;
    flex-wrap: wrap;
}

.cardColumnWrap {
    width: 530px;
    display: flex;
}

.leftFooter {
    display: flex;
}

.modalImgGroup {
    padding-top: 12px;
}
