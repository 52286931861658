.wrap {
    position: relative;
}

.thumbPreview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    margin-bottom: 6px;

    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
        transform: scale(0.9);
    }
}

.clearIcon {
    top: -3px;
    right: 2px;
    cursor: pointer;
    position: absolute;
    z-index:1;
}
