.wrap {
    //display: flex;
    //justify-content: center;
}

.contentWrap {
    flex: 1;
    margin: 0 auto;
    max-width: 1180px;
    padding-bottom: 24px;
}

.cardContentWrap {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-bottom:24px;
}

.cardColumnWrap {
    width: 530px;
    display: flex;
}

.rowWrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.badge {
    padding: 6px 40px !important;
    margin-left: 20px !important;
    border-radius: 8px;
}

.modalImgGroup {
    padding-top: 12px;
}
