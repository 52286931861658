.wrap {
    font-family: 'Montserrat',sans-serif;
    width: 100%;
}

.textareaWrap {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

.textarea {
    display: flex;
    flex: 1;
    padding: 6px 35px 32px 20px;
    border-radius: 10px;
    height: 36px;
    background-color: #F6F7FA;
    border: none;

    &:focus {
        outline: none;
    }
}

.suffixPadding {
    padding-right: 35px
}

.clearIcon {
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 13px;
}

.valueLength {
    //display: flex;
    //align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #C0BFC7;
    font-size: 12px;
}

.disabled {
    background-color: #C0BFC7;
    color: #F6F7FA
}

.error {
    border-color: #ED006D;
    border-width: 1px;
    border-style: solid;
}

.errorLength {
    color: #ED006D;
}

.buttonsWrap {
    display: flex;
    flex-wrap: wrap;
}


/* SCROLL BAR START */

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    height: 20px;
    background-color: #626669;
    opacity: 0.6;
    border-radius: 5px;
    padding: 15px;
}

/* SCROLL BAR END */
