.wrap {
    display: flex;
    align-items: center;
    background-color: var(--background-color);
    transition: background-color 0.3s;

    &:hover {
        background-color: var(--hover-background-color, var(--background-color));
    }
}
