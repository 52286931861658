.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 52px 130px 28px 0;
    width: auto;

    .wrapItem {
        max-width: 1180px;
        width: 100%;
    }
}

.onboardingImg {
    max-width: 500px;
    margin-top: 40px;
}
