// .modalCustomDialog {
//     transform: translate(0, 0) !important;
//     left: 8%;
// }

.modalCarousel:focus {
    outline: none;
}

.modalCarouselItem {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20px;

    img {
        max-width: 1080px;
        // height: 1080px;
        object-fit: contain;
    }
}