.modalImageGroup {
    &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__placeholder {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 1;
        flex-direction: column;
        align-self: center;
        margin-top: 35px;

        p {
            font-size: medium;
            color: #ced4da;
            max-width: 230px;
            text-align: center;
        }
    }

    &__main {
        display: flex;
        flex: 4;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        img {
            object-fit: cover;
            height: 200px;
            width: 356px;
            border-radius: 5px;

        }
    }

    &__all {
        display: flex;
        flex-wrap: wrap;
        flex: 4;
        & div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 7px;
        }

        .imageWrap {
            display: flex;
            flex-wrap: wrap;
        }

        img {
            cursor: pointer;
            width: 100px;
            height: 100px;
            object-fit: cover;
            transition: all 0.2s ease-in;
            border-radius: 5px;

            &:hover {
                transform: scale(0.9);
            }
        }
    }
}
