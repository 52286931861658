.wrap {
    display: flex;
    flex-basis: 100%;
    width: 100%;

    background-color: #eee;
    border-radius: 5px;
    padding: 10px;
    min-height: 250px;
}
