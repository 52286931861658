.adminWrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;

    &__sideBarContent {
        display: flex;
        flex-wrap: nowrap;
        flex-basis: 100%;
    }

    &__content {
        flex-basis: 100%;
    }

    &__linearBackground {
        background: linear-gradient(#FFFFFF 30%, 50%, #F6F7FA 70%);
    }
}

.authWrap {
    display: flex;
}
