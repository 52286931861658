.checkbox {
    display: flex;
    align-items: center;

    .checkboxLg {
        width: 24px;
        height: 24px;
        margin-right: 20px;

        &:checked {
            background-color: #00389C;
            border-color: #00389C;
        }
    }
}