.wrap {
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    min-width: 1180px;

    display: flex;
    flex-direction: column;
    row-gap: 28px;
}

.rowWrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.customDropdown {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;

    button {
        border-radius: 10px;
    }
}

.icon {
    height: 24px;
}
