.icon {
    width: 36px;
    height: 36px;
    object-fit: fill;
}

.rowLink {
    display: flex;
    flex: 1;
    align-self: stretch;
    text-decoration: none;
}

.pencil {
    cursor: pointer;
    height: 24px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
