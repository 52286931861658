.wrap {
    display: flex;
    flex: 1;
    align-items: center;
    //outline: none;
    border: none;
    border-radius: 10px;

}

.select {
    display: flex;
    flex: 1;
    align-items: center;

    height: 36px;
    background: #F6F7FA;
    border-radius: 10px;
    color: #626669;
    padding: 6px 35px 6px 20px;
    font-size: 18px;
    position: relative;
}

.suffixElement {
    display: flex;
    align-items: center;
    position: absolute;
    //top: 10px;
    height: 100%;
    right: 3px;
}

.error {
    border-color: #ED006D;
    border-width: 1px;
    border-style: solid;
}
