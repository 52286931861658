.wrap {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-end;

}

.barWrap {
    padding: 0 5px;
}


.bar {
    height: 100%;
    margin: 0 3px;

    background-color: #294689;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.barLabel {
    font-size: 16px;
    font-weight: 600;
    color: #FFF;
    text-align: center;
    padding: 10px;
}


.labelPrice {
    min-height: 8%;
    font-size: 11px;
    padding: 0 2px;
    text-align: center;
}

.line {
    min-height: 5%;
    margin: 0 49%;
}

.rangeBase {
    display: flex;
    font-size: 12px;
    color: #868690;
    margin-top: 5px;
    margin-right: -15px;
}

.rangeMax {
    justify-content: end;
}

.rangeMaxLast {
    justify-content: end;
    margin-right: 0;

}

.rangeFull {
    justify-content: space-between;
}
