.wrap {
    font-size: 18px;
    margin-bottom: 15px;
    span {
        color: #1B1D2C
    }

    a {
        margin-left: 5px;
        color: #00389C;
        text-decoration: none
    }
}
