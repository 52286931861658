.wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    height: 100%;

}

.imageAndContentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1180px;
    min-width: 600px;
    margin-left: -150px;

    img {
        margin-right: 60px;
    }
}

.contentWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none
    }
}
