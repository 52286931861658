.wrap {
    cursor: pointer;
}

.popover {
    height: 225px;
    max-width: none !important;
    width: 302px;

    img {
        width: 300px;
        height: 225px;
        object-fit: cover;
        cursor: pointer;
    }
}
