.wrap {
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    min-width: 1180px;

    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.rowWrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.wrapRight {
    margin-left: auto;
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
}

.row {
    position: relative;
    cursor: pointer;
    transform: none;
}

.filtered {
    color: #BA1274;
    font-weight: 800;
}

.paddingZeroFix {
    padding: 0 !important;
}

.checkboxLg {
    width: 25px !important;
    height: 25px !important;
}

.imageRow {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.default {
    cursor: default;
}
