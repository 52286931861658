.customCheckbox input[type='checkbox']:indeterminate{
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 2px;
    }
}
