@use "./global";

.wrap {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    padding: 18px 0;
    height: 94px; 
    color: #00266A;
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(123, 129, 149, 0.12);
    align-self: stretch;
    
    // На широких экранах всё по-прежнему
    @include global.bp-mobile-up {
        width: 100%;
    }

    // На экранах до 576px:
    @include global.bp-mobile-down {
        min-width: 1180px;
        // Убираем жёсткую высоту
        height: auto;
        // Если хотим расположить лого и ссылки друг под другом
        // flex-direction: column; 
        // Подгоняем отступы
        // padding: 12px 20px;
        // Можно выровнять элементы слева (или по центру)
        // align-items: flex-start;
    }

    &__container {
        display: flex;
        flex: 1;
        max-width: 1180px;
        justify-content: space-between;

        // На экранах меньше 1200px
        @include global.bp-desktop-down {
            margin: 0 30px;
        }

        // При ещё более узком экране (до 576px) 
        // можно тоже сделать flex-direction: column;
        @include global.bp-mobile-down {
            // flex-direction: column;
            align-items: flex-start; 
            // margin: 0; // Можно убрать внешние отступы
        }
    }
}

.logoAndLinks {
    display: flex;
    flex: 1;
    align-items: center;

    @include global.bp-mobile-down {
        // Разместить лого и ссылки вертикально
        // flex-direction: column;
        // align-items: flex-start;
        // Если ссылки слишком близко друг к другу, 
        // можно добавить gap:
        gap: 8px;
    }
}
