.wrap {
    font-family: 'Montserrat',sans-serif;
    display: flex;
    padding: 0;
    margin: 0;
}

.shrinkwrap {
    flex-basis: 0;
}

.clamped {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
