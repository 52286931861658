.wrap {
    padding-bottom: 82px;
    display: flex;
    justify-content: center;
}

.contentWrap {
    max-width: 1180px;
}

.cardsWrap {
    display: flex;
    gap: 40px;
}
