.selectableComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-weight: bold;

  &.selected {
    border-color: blue;
  }

  &:hover {
    border-color: red;
  }

  .text {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    width: min-content;
  }

  &.hovered {
    color: green;
  }
  img.icon {
    height: 36px;
    object-fit: fill;
}
}
