.contentWrap {
    flex: 1;
    margin: 0 auto;
    max-width: 1180px;
    padding-bottom: 24px;
}

.cardContentWrap {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-bottom: 12px;
    flex-wrap: wrap;
}

.cardColumnWrap {
    width: 530px;
    display: flex;
}

.modalImgGroup {
    padding-top: 12px;
}
