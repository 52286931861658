.wrap {
    position: relative;

}

.deleteButton {
        position: absolute;
        top: -10px;
        right: -15px;
        background-color: transparent;
        border: none;

    svg {
        position: relative;
    }
}

.iconFill {
    background-color: white;
    position: absolute;
    width: 9px;
    height: 9px;
    top: 3px;
    right: 9px;
}

.revertButton {
    position: absolute;
    top: -9px;
    right: -8px;
    background-color: black;
    border-radius: 50%;
    display: flex;
    padding: 1px;
    svg {
        position: relative;
    }
}

.active {
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 8px;
    transition: all 0.3s ease-out;

    &:hover {
        transform: none;
    }
}
