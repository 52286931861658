.wrap{
    // cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    position: relative
}

.icon {
    cursor: pointer;
    margin-left: 10px;
}

.clearIcon {
    cursor: pointer;
    // position: absolute;
    // top: 0;
    margin-left: 4px;
}

.selectSearchWrap {
    width: 100%;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    top: -31px;
    left: -6px;
    z-index: 5;
}

.tableHeadWrap {
    cursor: pointer;
    // display: flex;
    // flex: 1;
}