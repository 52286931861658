.wrap {
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    min-width: 1180px;

    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.rowWrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.wrapRight {
    margin-left: auto;
}

.alignRight {
    text-align: right;
}

.row {
    position: relative;
    cursor: pointer;
    transform: scale(1, 1);
}

.filtered {
    color: #BA1274;
    font-weight: 800;
}

.paddingZeroFix {
    padding: 0 !important;
}

.titleWrap {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.sortControls {
    display: flex;
    gap: 8px;
}

.statiscticsBlock {
    display: flex;
    gap: 16px;
    align-items: center;
}

.statiscticsBlockText {
    color: rgb(1,55,155);
    font-size: 1.3em;
    margin: 0;
}

.imageRow {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.default {
    cursor: default;
}

.customDropdown {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    margin-left: auto;

    button {
        border-radius: 10px;
    }
}
